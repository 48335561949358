import React, { FC, Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { EventOrderType, EventTicketType } from "data/types";
import { QRCodeCanvas } from 'qrcode.react'
import FormItem from "containers/PageAddListing1/FormItem";
import Input from "shared/Input/Input";

interface ModalOrderSaveTicketsProps {
  renderChildren?: (p: { openModal: () => void }) => React.ReactNode;
  eventId?: string,
  eventName?: string,
}

const ModalAddAttendee: FC<ModalOrderSaveTicketsProps> = ({ eventId='', eventName='', renderChildren }) => {
  const [showModal, setShowModal] = useState(false);

  // FOR RESET ALL DATA WHEN CLICK CLEAR BUTTON
  //
  function closeModal() {
    setShowModal(false);
  }

  function openModal() {
    setShowModal(true);
  }

  const renderButtonOpenModal = () => {
    return renderChildren ? (
      renderChildren({ openModal })
    ) : (
      <button onClick={openModal}>Select Date</button>
    );
  };

  return (
    <>
      {renderButtonOpenModal()}
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="HeroSearchFormMobile__Dialog relative z-50"
          onClose={closeModal}
        >
          <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
            <div className="flex h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out transition-transform"
                enterFrom="opacity-0 translate-y-52"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in transition-transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-52"
              >
                <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between ">
                  <>
                    <div className="absolute left-4 top-4">
                      <button
                        className="focus:outline-none focus:ring-0"
                        onClick={closeModal}
                      >
                        <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                      </button>
                    </div>

                    <div className="flex-1 pt-12 p-1 flex flex-col overflow-hidden" style={{margin: 16}}>
                        <h1 className="font-semibold text-3xl">Invite New Guest for {eventName}</h1>
                        <div className="mt-3" style={{marginTop: 25,}}>
                        <FormItem className="mt-3 mb-3" label="First Name">
                            <Input/>
                        </FormItem>
                        <FormItem className="mt-3 mb-3" label="Last Name">
                            <Input/>
                        </FormItem>
                        <FormItem className="mt-3 mb-3" label="Email">
                            <Input/>
                        </FormItem>
                        <FormItem className="mt-3 mb-3" label="Ticket Type">
                            <Input/>
                        </FormItem>
                        </div>
                        
                    </div>

                    <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between">
                      <button
                        type="button"
                        className="underline font-semibold flex-shrink-0"
                        onClick={() => {}}
                      >
                        Clear data
                      </button>
                      <ButtonPrimary
                        sizeClass="px-6 py-3 !rounded-xl"
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        Invite
                      </ButtonPrimary>
                    </div>
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ModalAddAttendee;

import React, { FC, useState } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import rightImg from "images/newsletter.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import Input from "shared/Input/Input";
import {contactUs, joinNewsLetter, joinNewsLetterWithResult} from "./../../hooks/EventGoAPI"
import { CircularProgress } from "@mui/material";

export interface SectionSubscribe2Props {
  className?: string;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {

  const [userEmail, setUserEmail] = useState('')
  const [loading, setLoading] = useState(false)

  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl">Join our newsletter</h2>
        {/* <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
          Read and share new perspectives on just about any topic. Everyone’s
          welcome.
        </span> */}

        <div className="mt-10 relative max-w-sm">
        <form>
          <Input
            required
            aria-required
            placeholder="Enter your email"
            type="email"
            rounded="rounded-full"
            value={userEmail}
            onChange={(e)=>{
              let email = e.target.value
              setUserEmail(email)
              
            }}
          />
        </form>
        {!loading?<ButtonCircle
            onClick={()=>{
              if (userEmail.includes('@')){
                setLoading(true)
                joinNewsLetterWithResult(userEmail).then((val)=>{
                  console.log("joinNewsLetter finished")
                  if(val){
                    alert("Congrats! You have successfully joined our newsletter")
                  }
                  setLoading(false)
                  setUserEmail('')
                }).catch((err)=>{
                  alert(err)
                  setLoading(false)
                  setUserEmail('')
                })
              }
              else{
                alert('Please enter correct email')
                setUserEmail('')
              }
            }}
            className="absolute transform top-1/2 -translate-y-1/2 right-[5px]"
          >
            <i className="las la-arrow-right text-xl"></i>
          </ButtonCircle>:
          <CircularProgress className="absolute transform top-1.5 -translate-y-1/2 right-[5px]" size={30} color={"warning"}></CircularProgress>}          
        </div>
      </div>
      <div className="flex flex-grow justify-end">
        <NcImage className="rounded-xl self-right" src={rightImg}  style={{width:400}}/>
      </div>
    </div>
  );
};

export default SectionSubscribe2;

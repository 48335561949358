import Label from "./../../components/Label/Label";
import React, { FC, useState, Fragment, useEffect } from "react";
import { useParams } from "react-router";
import { useSearchParams} from "react-router-dom"
import Avatar from "./../../shared/Avatar/Avatar";
import ButtonPrimary from "./../../shared/Button/ButtonPrimary";
import Input from "./../../shared/Input/Input";
import Select from "./../../shared/Select/Select";
import Textarea from "./../../shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet-async";
import Grid from '@mui/material/Grid2';
import { DataGrid } from "@mui/x-data-grid";
import { Tab } from "@headlessui/react";
import { auth, fs } from "firebaseSetup";
import { Box, Paper, Button, IconButton, Typography, useTheme, Drawer } from "@mui/material";
import { CSVLink } from 'react-csv'
import { CSVHeaderType, EventOrderType } from "data/types";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { getDocs, query, collection, orderBy, DocumentData, onSnapshot} from 'firebase/firestore'

export interface AccountPageProps {
  className?: string;
}

const EventOrders: FC<AccountPageProps> = ({ className = "" }) => {

  const {id} = useParams()
  const [searchParams] = useSearchParams();
  const viewType = searchParams.get('view');

  

    const [eventItem, setEventItem] = useState({
        title: '',
        description: '',
        id: '',
    })

    let [categories] = useState([
      "Orders",
      "Tickets",
      "Invitations",
      "Attend Requests",
      "Refund Requests"]);

      const [currentTime,setCurrentTime] = useState('')


    const [allOrders, setAllOrders] = useState<EventOrderType[]>([])
    const [currentOrder, setCurrentOrder] = useState<EventOrderType>({
      id: '',
      amount: 0,
      eventId: '',
      title: '',
      description: '',
      photoURL: '',
      orgId:'',
      paymentId: '',
      uniqueId:'',
      userEmail:'',
      userUID:'',
      date:'',
      type:'',
    })

    const [eventDashboard, setEventDashboard] = useState({
      dashboardItems: [
          {
              title: '',
              total: 0,
              time: '',
              notice: '',
          }
      ],
      eventItem: {
          title: 'Loading...',
          id: '',
          description: 'Loading...'
      },
      currentTime: 'Loading...',
      mode: 'orders',
      tickets: [],
      members: [],
      orders: [],
      invites: [],
  })

  const [invitedData, setInvitedData] = useState<DocumentData[]>([])
  const [requestData, setRequestData] = useState<DocumentData[]>([])

  function loadInvitation(eventId: string){
    const inviteRef = query(collection(fs, "events",eventId, 'invites'), orderBy('timestamp','desc'));
    getDocs(inviteRef).then((snapshot)=>{
      let members: DocumentData[] = []
          snapshot.docs.forEach((doc) => {
            let docData = doc.data()
            docData.id = doc.id
            if (docData.timestamp != null){
              docData.timestamp = docData.timestamp.toDate().toLocaleString('en')
            }
            members.push(docData)
          })
          setInvitedData(members)
    })
  }

  function loadRequests(eventId: string){
    const inviteRef = query(collection(fs, "events",eventId, 'requests'), orderBy('timestamp','desc'));
    getDocs(inviteRef).then((snapshot)=>{
      let members: DocumentData[] = []
          snapshot.docs.forEach((doc) => {
            let docData = doc.data()
            docData.id = doc.id
            if (docData.timestamp != null){
              docData.timestamp = docData.timestamp.toDate().toLocaleString('en')
            }
            members.push(docData)
          })
          setRequestData(members)
    })
        
       
  }
    

    useEffect(() => {
      console.log('triggered');
      const viewT = viewType || "Orders"

      setCurrentTime("")
      loadInvitation(id || "")
      loadRequests(id || "")

          let userUID = ""
            userUID = auth.currentUser?.uid || ""
            let userToken = localStorage.getItem('user_token') || ""
            let eventId = id || ""
            if (userToken != null && userToken != "" && eventId != ""){
              

              fetch(`https://us-central1-blink-574af.cloudfunctions.net/v1OrganizerAuthenticatedGetOrganizerDetailedEvents?event_id=${eventId}&full_data=${true}&mode=orders`,{
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + userToken
              },
  
       }).then((response) => {
           const responseTask = response.json()
           responseTask.then((data) => {
               let dashboardItem = eventDashboard
               if (data.success != null && data.success){
                   //load data
                   
                   if (data.currentTime != null){
                       dashboardItem.currentTime = data.currentTime
                   }
                   
                   if (data.overview != null){
                       console.log(data.overview)
                       let overview = {...data.overview}
                       dashboardItem.dashboardItems = [
                           {
                             title: 'Total Sales',
                             total: overview.totalSales + "CAD",
                             time: dashboardItem.currentTime,
                             notice: '',
                           },
                           {
                             title: 'Tickets Sold',
                             total: overview.tickets,
                             time: dashboardItem.currentTime,
                             notice: '',
                           },
                           {
                             title: 'Total Members',
                             total: overview.members,
                             time: dashboardItem.currentTime,
                             notice: '',
                           },
                           {
                             title: 'Page Views',
                             total: overview.views,
                             time: dashboardItem.currentTime,
                             notice: '',
                           },
                           {
                             title: 'Total Form Submissions',
                             total: overview.formSubmissions,
                             time: dashboardItem.currentTime,
                             notice: '',
                           },
                           {
                             title: 'Total Invoices',
                             total: overview.invoices,
                             time: dashboardItem.currentTime,
                             notice: '',
                           },
                         ]
                   }
                   
                   if (data.event != null){
                       dashboardItem.eventItem = data.event
                   }
                   if (data.orders != null){
                       if (data.orders.data != null){
                          dashboardItem.orders = data.orders.data
                          setAllOrders(data.orders.data)
                       }
                       if (data.orders.headers != null){
                         setOrderColumn(data.orders.headers)
                       }
                   }
                   if (data.tickets != null){
                     if (data.tickets.data != null){
                       dashboardItem.tickets = data.tickets.data
                     }
                   }
                   setCurrentTime(dashboardItem.currentTime)
                   setEventDashboard(dashboardItem)
               }
               else{
                 console.log(data)
                 if (data.error == "token"){
                  auth.currentUser?.getIdToken(true).then((userToken) => {
                    localStorage.setItem('user_token',userToken)
                    window.location.reload()
                   })
                 }
                 
               }
               

           })
       })


            }
          else{

          }

  }, [eventDashboard]);

  const [orderColumn, setOrderColumn] = useState([
    { field: "id",  headerName: "Order ID" },
    { field: "name",  headerName: "Name" },
    { field: "userEmail",  headerName: "Email" },
    { field: "amount",  headerName: "Amount (CAD)" },
    { field: "tickets",  headerName: "Tickets" },
    { field: "lang",  headerName: "Lang" },
    { field: "couponCode",  headerName: "Coupon" },
    { field: "couponDesc",  headerName: "Coupon Desc" },
    { field: "ipAddress",  headerName: "IP"},
    { field: "ipLocation",  headerName: "IP Location"},
    { field: "timestamp",  headerName: "Date" },
  ])

  const [ticketColumn, setTicketColumn] = useState([
    { field: "id", flex: 0.3,  headerName: "Ticket ID" },
    { field: "userName", flex: 0.3,  headerName: "Name" },
    { field: "ticketName", flex: 0.5,  headerName: "Ticket Name" },
    { field: "tickets", flex: 0.5,  headerName: "Tickets" },
    { field: "checkin", flex: 0.5,  headerName: "Check-Ins" },
    { field: "timestamp", flex: 0.4,  headerName: "Date" },
  ])

  const [inviteColumn, setInviteColumn] = useState([
    { field: "id",  headerName: "Invite ID" },
    { field: "firstName",  headerName: "First Name" },
    { field: "lastName",   headerName: "Last Name" },
    { field: "email",  headerName: "Email" },
    { field: "orderId", headerName: "Order" },
    { field: "status", headerName: "Status" },
    { field: "timestamp", headerName: "Created Date" },
  ])

  const [requestColumn, setRequstColumn] = useState([
    { field: "id",  headerName: "Request ID" },
    { field: "firstName",  headerName: "First Name" },
    { field: "lastName",   headerName: "Last Name" },
    { field: "email",  headerName: "Email" },
    { field: "formSubmissionId", headerName: "Form" },
    { field: "status", headerName: "Status" },
    { field: "fromUA", headerName: "User Agent" },
    { field: "timestamp", headerName: "Created Date" },
  ])

  const [orderCSVHeader, setOrderCSVHeader] = useState<CSVHeaderType[]>([
    { key: "id",  label: "Order ID" },
    { key: "name",  label: "Name" },
    { key: "userEmail",  label: "Email" },
    { key: "amount",  label: "Amount (CAD)" },
    { key: "tickets",  label: "Tickets" },
    { key: "lang",  label: "Lang" },
    { key: "couponCode",  label: "Coupon" },
    { key: "couponDesc",  label: "Coupon Desc" },
    { key: "ipAddress",  label: "IP"},
    { key: "ipLocation",  label: "IP Location"},
    { key: "timestamp",  label: "Date" },
  ])

  const [orderRow, setOrderRow] = useState<string>('{}')
  const [orderOpen, setOrderOpen] = useState(false)
  const OrderDrawerList = (
    <Drawer open={orderOpen} onClose={()=>{ setOrderOpen(false)}}>
       <Box 
      role="presentation" 
      style={{width: '50vw'}}
      >
        <div className="relative space-y-8" style={{margin: '16px'}}>
        <ButtonSecondary className="ml-3" onClick={()=>{
          setOrderOpen(false)
        }}>Back</ButtonSecondary>
      </div>

      <div className="relative space-y-2" style={{margin: '16px'}}>
          <div className="text-2xl font-bold">Order Details</div>
          <br/>
          {Object.keys(JSON.parse(orderRow) || {}).map((keyItm) => {
            let headerN = ""
            let orderRowVal = {}
            orderRowVal = JSON.parse(orderRow) || {}
            let orderHeaderN = orderColumn.filter((itm) => {
              return itm.field == keyItm
            })
            if (orderHeaderN.length > 0){
              headerN = orderHeaderN[0].headerName
            }
            return headerN && <div>
            <span>{headerN}: </span>
            <span className="font-bold">{(JSON.parse(orderRow) || {})[keyItm]}</span>  
          </div>
          }) }
          {/* <div>
            <span>Customer Name:  </span>
            <span className="font-bold">A</span>  
          </div>
          <div>
            <span>Name:  </span>
            <span className="font-bold">A</span>  
          </div>
          <div>
            <span>Name:  </span>
            <span className="font-bold">A</span>  
          </div>
          <div>
            <span>Name:  </span>
            <span className="font-bold">A</span>  
          </div> */}
        </div>

      </Box>
    </Drawer>
  )

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Event Organizer Dashboard</title>
      </Helmet>
      <CommonLayout eventId={id || ""} eventTitle={eventDashboard.eventItem.title}>
        <div className="space-y-6 sm:space-y-8">
          {/* HEADING */}
          <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {categories.map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-secondary-900 text-secondary-50 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                { OrderDrawerList }
                <div className="flex align-center justify-between mt-3">
                  <h2 className="text-3xl font-semibold">Orders ({eventDashboard.orders.length})</h2>
                  {eventDashboard.orders.length > 0 && 
                  <CSVLink 
                  className="!leading-none" 
                  filename="event-order-data.csv" 
                  style={{}} 
                  data={eventDashboard.orders} 
                  headers={orderCSVHeader}>
            <span className="font-semibold text-1xl">Download Data</span>
          </CSVLink>}
                </div>
              
          <div className="mt-5 w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <DataGrid 
            checkboxSelection
            onCellClick={(e)=>{
              let orderId = ""
              if (e.row != null){
                if (e.row.id != null){
                  orderId = e.row.id
                }
              }
              let rowObj = {
                userName: '',
                userEmail: '',
                uniqueId: '',
                paymentId: '',
                id: ''
              }
              rowObj = e.row
              setOrderRow(JSON.stringify(rowObj))
              // let filteredOrder = allOrders.filter((ord) => {
              //   return ord.uniqueId == orderId
              // })
              // if (filteredOrder.length > 0){
              //   setCurrentOrder(filteredOrder[0])
              // }
              setOrderOpen(true)
            }}
            sx={{ overflowX: 'scroll' }}
        rows={eventDashboard.orders} 
        columns={orderColumn} />
              </Tab.Panel>
              <Tab.Panel>
              <h2 className="text-3xl font-semibold">Tickets ({eventDashboard.tickets.length})</h2>
          <div className="mt-3 w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <DataGrid 
            checkboxSelection 
            sx={{ overflowX: 'scroll' }}
        rows={eventDashboard.tickets} 
        columns={ticketColumn} />
              </Tab.Panel>
              <Tab.Panel>
              <h2 className="text-3xl font-semibold">Invitations ({invitedData.length})</h2>
          <div className="mt-3 w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <DataGrid 
            checkboxSelection 
            sx={{ overflowX: 'scroll' }}
        rows={invitedData} 
        columns={inviteColumn} />
              </Tab.Panel>
              <Tab.Panel>
              <h2 className="text-3xl font-semibold">Attend Requests ({requestData.length})</h2>
          <div className="mt-3 w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <DataGrid 
            checkboxSelection 
            sx={{ overflowX: 'scroll' }}
        rows={requestData} 
        columns={requestColumn} />
              </Tab.Panel>
              <Tab.Panel>
              <h2 className="text-3xl font-semibold">Refund Requests (0)</h2>
          <div className="mt-3 w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          <DataGrid 
            checkboxSelection 
            sx={{ overflowX: 'scroll' }}
        rows={[]} 
        columns={orderColumn} />
              </Tab.Panel>
            </Tab.Panels>
            </Tab.Group>
           
        </div>
      </CommonLayout>
    </div>
  );
};

export default EventOrders;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import SectionAds from "./SectionAds";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionMagazine5 from "./SectionMagazine5";
import SectionLatestPosts from "./SectionLatestPosts";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { PostDataType, TaxonomyType } from "data/types";
import { convertDataToTaxonomyType, DEMO_CATEGORIES } from "data/taxonomies";

// DEMO DATA
// DEMO POST FOR MAGAZINE SECTION
const BlogPage: React.FC = () => {

  function convertTime(timeStr:string){
      var ts = Date.parse(timeStr);
      const formatter = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' , hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
      const formattedDate = formatter.format(ts);
      return formattedDate;
  }

  const [posts, setPosts] = useState([]);
  const [tags, setTags] = useState<TaxonomyType[]>([]);
  useEffect(()=>{
    
    const value = localStorage.getItem('tagInfo');
    var localTags: TaxonomyType[] = [];
    if(value === null){
      localTags = JSON.parse(JSON.stringify(DEMO_CATEGORIES));
      localTags.forEach((cate:any)=>{
        cate.count = 0;
      });
    }else{
      const tagInfo = JSON.parse(value);
      const tagList = tagInfo['tag_list'];
      const tagNames = tagInfo['tag_name'];
      localTags = convertDataToTaxonomyType(tagList, tagNames);
    }

    fetch("https://us-central1-blink-574af.cloudfunctions.net/v1NewsApIList",{
      headers: {
        "Content-Type": "application/json",
      },

    }).then((response) => {
        if(response.status === 200){
          const responseTask = response.json();
          responseTask.then((resp) => {
              if(resp.success === true){
                const items = resp.data.map((data:any):PostDataType => {
                  var category = null;
                  if(data.labels !== undefined){
                    data.labels.forEach((blogTag:any)=>{
                      localTags.forEach((tag:any)=>{
                        if(tag.name === blogTag){
                          category = {
                              "id":tag.id,
                              "name": tag.name,
                              "href": tag.href,
                              "count": tag.count,
                              "color": tag.color,
                              'taxonomy': "category",
                            };
                            tag.count = tag.count!+1;
                            return;
                          }
                      });
                    })
                  }else{
                    //for you
                    localTags.forEach((tag:any)=>{
                      if(tag.id === 1){
                        category = {
                            "id":tag.id,
                            "name": tag.name,
                            "href": tag.href,
                            "count": tag.count,
                            "color": tag.color,
                            'taxonomy': "category",
                          };
                          tag.count = tag.count!+1;
                          return;
                        }
                    });
                  }
                  return {
                    ...data,
                    published:convertTime(data.published),
                    categories:[category]
                  }
                });
                setTags(localTags as any);
                setPosts(items);
              }
          })
        }
      })
  },[]);

  return (
    <div className="nc-BlogPage overflow-hidden relative">
      <Helmet>
        <title>News || Event Go</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />
      {/* ======== ALL SECTIONS ======== */}
      {/* ======= START CONTAINER ============= */}
      <div className="container relative">
        {/* === SECTION 8 === */}
        <SectionLatestPosts className="py-16 lg:py-28" posts={posts} tags={tags} />

        {/* === SECTION 1 === */}
        {/* <SectionSubscribe2 className="pb-16 lg:pb-28" /> */}
      </div>
    </div>
  );
};

export default BlogPage;


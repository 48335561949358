import { TaxonomyType } from "data/types";
import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { clearHomeFilter, setHomeFilter } from "store";

export interface UserTagProps {
  className?: string;
  tag: TaxonomyType;
  hideCount?: boolean;
  selected: boolean;
}

const UserTag: FC<UserTagProps> = ({ className = "", tag, hideCount = false, selected = false}) => {
  const dispatch = useDispatch()

  return (
    <div
      className={`cursor-pointer nc-Tag inline-block ${selected?'bg-orange-200':'bg-white'} text-sm text-neutral-6000 dark:text-neutral-300 py-2 px-3 rounded-lg border border-neutral-100 md:py-2.5 md:px-4 dark:${selected?'bg-yellow-700':'bg-neutral-700'} dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 ${className}`}
      data-nc-id="Tag"
      onClick={()=>{

      }}
    >
      {`${tag.name}`}
      {!hideCount && (
        <span className="text-xs font-normal"> ({tag.count})</span>
      )}
    </div>
  );
};

export default UserTag;

import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import rightImgDemo from "images/about-us-home.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Logo from "shared/Logo/Logo";

export interface SectionBecomeAnAuthorProps {
  className?: string;
  rightImg?: string;
}

const SectionBecomeAnAuthor: FC<SectionBecomeAnAuthorProps> = ({
  className = "",
  rightImg = rightImgDemo,
}) => (
  <div
    className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center  ${className}`}
    data-nc-id="SectionBecomeAnAuthor"
  >
    <div className="flex-shrink-0 mb-16 lg:mb-0 lg:mr-10 lg:w-2/5">
      {/* <Logo className="w-20" /> */}
      <h2 className="font-semibold text-3xl sm:text-4xl mt-6 sm:mt-11">
        About us
      </h2>
      <span className="block mt-6 text-neutral-500 dark:text-neutral-400">
        EventGo charts novel paths in the event industry, leveraging pioneering technology to enhance event visibility, streamline ticketing, and deliver impactful experiences affordably. Our data-driven approach sets trends for sustainable event management, elevating each gathering into a lasting memory. We're not just part of the event ecosystem; we're redefining it, merging strategic production with seamless execution.
      </span>
      <ButtonPrimary href="/about" className="mt-6 sm:mt-11">
        Learn more
      </ButtonPrimary>
    </div>
    <div className="flex flex-grow justify-end">
      <NcImage  className="rounded-xl" src={rightImg} style={{ width: 400 }} />
    </div>
  </div>
);

export default SectionBecomeAnAuthor;

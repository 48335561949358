import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHero2 from "components/SectionHero2/SectionHero2";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import SectionGridFeatureEvents from "./SectionGridFeatureEvents";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType, EventDataType } from "data/types";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import useEventDataLoader from "hooks/useEventDataLoader";
import SectionHero3 from "components/SectionHero/SectionHero3";
import SectionGridOrganizerBox from "components/SectionGridOrganizerBox/SectionGridOrganizerBox";
import useHomePageDataLoader from "hooks/useHomePageDataLoader";
import SectionHomeContactus from "components/SectionHomeContactus/SectionHomeContactus";
import {isMobile} from 'react-device-detect'
import { useDispatch, useSelector } from "react-redux";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import { Helmet } from "react-helmet-async";
import { homeCityPanelLocation, homeEvents, homeSearchFilter, updateEvents } from "store";

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "/search?location=toronto",
    name: "Toronto",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://cdn.britannica.com/35/100235-050-CE3936EE/view-CN-Tower-Toronto-skyline-observation-deck.jpg",
  },
  {
    id: "2",
    href: "",
    name: "Vancouver",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://t1.gstatic.com/licensed-image?q=tbn:ANd9GcSmuL2MuAwGAFUK1lRTPcqEvXdGyozySS9ul46W-TuOI9LKHQjh2TbkBrpiLOSQSbQO",
  },
  {
    id: "2",
    href: "",
    name: "Calgary",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bb/Downtown_Calgary_2020-4.jpg/800px-Downtown_Calgary_2020-4.jpg",
  },
  {
    id: "2",
    href: "",
    name: "Montreal",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://upload.wikimedia.org/wikipedia/commons/4/4f/Montreal-canada-parc-urban.jpg",
  },
  {
    id: "2",
    href: "",
    name: "Edmonton",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcRja3p0-TepfnqZ3xFKkrhsxcTWaBrTBpkKYNb6SbV4WoockeXVwjmtrcTDpGzxV5By",
  },
  {
    id: "2",
    href: "",
    name: "Ottawa",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://t2.gstatic.com/licensed-image?q=tbn:ANd9GcQDnwVh37rgTRevL8QkcFn12sk2nODvGzz0ck5r1c3vFvDnmLSoCZBhACWce9AiGSr9",
  },
];

const DEMO_CATS_2: TaxonomyType[] = [
  {
    id: "1",
    href: "/listing-stay",
    name: "Enjoy the great cold",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/5764100/pexels-photo-5764100.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "222",
    href: "/listing-stay",
    name: "Sleep in a floating way",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/2869499/pexels-photo-2869499.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "3",
    href: "/listing-stay",
    name: "In the billionaire's house",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "4",
    href: "/listing-stay",
    name: "Cool in the deep forest",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/247532/pexels-photo-247532.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "5",
    href: "/listing-stay",
    name: "In the billionaire's house",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];

function PageHome() {

  const dispatch = useDispatch()
  const eventListings = useSelector(homeEvents)
  const searchFilter = useSelector(homeSearchFilter)
  const [searchParams] = useSearchParams();
  const tagId = searchParams.get('tag') || '';
  const homePageData = useHomePageDataLoader({isLoading: false,initEvents: [],tabs: [], tabActive: ''})
  const citySelected = useSelector(homeCityPanelLocation)

  const eventPanel = useRef<HTMLDivElement | null>(null);


  useEffect(()=>{
    if(eventListings.length === 0 && !searchFilter.valid){
      dispatch(updateEvents(homePageData.events))
    }
  }, [homePageData])


  useEffect(()=>{
    if(citySelected != null && citySelected.length > 0){
      scrollToComponent()
    }
  }, [citySelected])

    // 定义滚动到组件的函数
    const scrollToComponent = () => {
      if(eventPanel.current != null){
        eventPanel.current.scrollIntoView({
          behavior: 'smooth',  // 平滑滚动效果
          block: 'start'       // 滚动到顶部对齐位置
        });
      }
    };
       
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <Helmet>
        <title>Event Go - Love your events</title>
      </Helmet>

      <BgGlassmorphism />

      <div hidden={!isMobile} className="relative space-y-6 lg:space-y-6">
        <SectionHero3 className="" bannerImages={homePageData.banner}/>
      </div>

      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        {/* SECTION HERO */}
        
       {!isMobile && <SectionHero3 className="" bannerImages={homePageData.banner}/>} 
        
        {/* SECTION */}
        <div className="relative py-8" ref = {eventPanel} style={{marginTop: isMobile ? 0 : 75}}>
          <BackgroundSection />
          <SectionGridFeatureEvents 
          heading = "Love your events"
          subHeading = "Popular events are hosted now"
          tabs={homePageData.tabs}
          tabIds={homePageData.tabIds}          
          />
        </div>

        

        {/* SECTION */}
        <SectionGridOrganizerBox organizers={homePageData.organizers}/>

         {/* SECTION */}
         <div className="relative py-16">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div>

        {/* SECTION 1 */}
        <SectionSliderNewCategories
          categories={DEMO_CATS}
          
          uniqueClassName="PageHome_s1"
          heading="Highlight Places"
          subHeading="Locations to explore more events on Event Go"
        />
        <SectionSubscribe2 />
        

       

        {/* <SectionHomeContactus /> */}

        

        {/* SECTION */}
        

        {/* SECTION 1 */}
        {/* <SectionSliderNewCategories
          heading="Explore by types of stays"
          subHeading="Explore houses based on 10 types of stays"
          categoryCardType="card5"
          itemPerRow={5}
          uniqueClassName="PageHome_s3"
        /> */}

        {/* SECTION */}
        {/* <SectionVideos /> */}

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="PageHome_" />
        </div> */}
      </div>
    </div>
  );
}

export default PageHome;

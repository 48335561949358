import CardCategoryBox1 from "components/CardCategoryBox1/CardCategoryBox1";
import CardOrganizerBox from "components/CardOrganizerBox/CardOrganizerBox";
import Heading from "components/Heading/Heading";
import { TaxonomyType, OrganizerDisplayType } from "data/types";
import React, { useEffect, useState } from "react";
import HeaderFilter from "containers/PageHome/HeaderFilter";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Paper, Typography,  Skeleton, Stack, Card, CardHeader, CardContent, CardMedia, CardActions, IconButton, Button } from '@mui/material'


export interface SectionGridOrganizerProps {
  organizers?: OrganizerDisplayType[];
  headingCenter?: boolean;
  className?: string;
  gridClassName?: string;
}


const SectionGridOrganizerBox: React.FC<SectionGridOrganizerProps> = ({
  organizers = [],
  headingCenter = false,
  className = "",
  gridClassName = "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3",
}) => {

  const [loading, setLoading] = useState(false)
  const [allOrganizers, setAllOrganizers] = useState(organizers)
  
  const loadMoreOrganizer = () => {
      setLoading(true)
      var url = 'https://us-central1-blink-574af.cloudfunctions.net/v1EventsAPIFetchRecommendedOrganizer?count='+`${organizers.length +6}`
      fetch(url,{
           headers: {
             "Content-Type": "application/json",
           },
      }).then((response) => {
          
        const responseTask = response.json();
        responseTask.then((data) => {
          if(data['count'] && data['count'] > 0){
            const moreOrganizers = data['data']
            organizers.push(...moreOrganizers.slice(organizers.length))
            setAllOrganizers(organizers)
          }
          setLoading(false)
        }).catch((err)=>{
          setLoading(false)
        })
    })
  }

  function LoadingEventView(){
    return (
      <Card sx={{ maxWidth: 360, m: 2 }}>
        <CardHeader
          avatar={<Skeleton animation="wave" variant="circular" width={80} height={80} />}
          title={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }}/>}
          subheader={<Skeleton animation="wave" height={10} width="40%" />}
        />
      </Card>
    )
  }

  function LoadingTotalView(){
    return <div className={`grid ${gridClassName} gap-5 sm:gap-6 md:gap-8`}>
      <LoadingEventView />
      <LoadingEventView />
      <LoadingEventView />
      <LoadingEventView />
      <LoadingEventView />
      <LoadingEventView />
      </div>
  }

  useEffect(()=>{
    if(allOrganizers.length === 0 ){
      setAllOrganizers(organizers)
    }
  }, [organizers])

  return (
    <div className={`nc-SectionGridCategoryBox relative ${className}`}>
      <HeaderFilter
        tabActive={""}
        subHeading={"See who is managing events on Event Go"}
        tabs={[]}
        heading={"Event Go Partners"}
        onClickTab={() => {}}
      />
      {allOrganizers.length === 0 ? <LoadingTotalView/> : 
      <div className={`grid ${gridClassName} gap-5 sm:gap-6 md:gap-8`}>
      {allOrganizers.map((item, i) => (
        <CardOrganizerBox key={i} organizer={item}/>
      ))}
    </div>
      }
      
      
      <div className="mt-16 flex flex-col sm:flex-row justify-center space-y-3 sm:space-y-0 sm:space-x-5">
        <ButtonSecondary loading={loading} onClick={()=>{
          loadMoreOrganizer()
        }}>Show me more </ButtonSecondary>
        <ButtonPrimary href="/account-organization">Become Organizer</ButtonPrimary>
      </div>
    </div>
  );
};

export default SectionGridOrganizerBox;

// store.js
import { configureStore, createSlice } from '@reduxjs/toolkit';

const defaultFilterValue = {
  'location':'',
  'size':'',
  'startDate':'',
  'endDate':'',
}

// 创建一个 slice
const homeSearchInfoSlice = createSlice({
  name: 'homeSearchInfoSlice',
  initialState: {
    filter: {
      filter:{
        'location':'',
        'size':'',
        'startDate':'',
        'endDate':'',
      },
      tag:'all',
      tagName:'All',
      valid:false
    },
  },

  reducers: {
    setHomeFilter: (state, action) => {
      if(action.payload['filter'] && (action.payload['filter']['startDate'] 
        || action.payload['filter']['keywords'] 
        || action.payload['filter']['location']
        || action.payload['filter']['size'])){        
        state.filter.filter = {
          ...defaultFilterValue,
          ...action.payload['filter']
        };
        state.filter.tag = 'all';
        state.filter.tagName = 'All';
        state.filter.valid = true
      }else{
        state.filter.tag = action.payload['tag'] ?? 'all';
        state.filter.tagName = action.payload['name'] ?? 'All';
        state.filter.valid = true
      }
    },
    clearHomeFilter: (state) => {
        state.filter = {
          filter:{},
          tag:'all',
          tagName:'All',
          valid:true
        };
    },
  },
});

// 创建一个 slice
const homeEventsSlice = createSlice({
  name: 'homeEventsSlice',
  initialState: {
    events: [],
  },

  reducers: {
    updateEvents: (state, action) => {
      state.events = action.payload;
    },
  },
});

const eventDetailAlbumSlice = createSlice({
  name: 'eventDetailAlbumSlice',
  initialState: {
    photos: [],
  },

  reducers: {
    updateEventAlbum: (state, action) => {
      state.photos = action.payload;
    },
  },
});

const homeCityPanelSlice = createSlice({
  name: 'homeCityPanelSlice',
  initialState: {
    location: '',
  },

  reducers: {
    selectLocation: (state, action) => {
      state.location = action.payload;
    },
  },
});

// 导出 actions 和 reducer
export const { setHomeFilter, clearHomeFilter } = homeSearchInfoSlice.actions;
export const { updateEvents } = homeEventsSlice.actions;
export const { updateEventAlbum } = eventDetailAlbumSlice.actions;
export const { selectLocation } = homeCityPanelSlice.actions;


const store = configureStore({
  reducer: {
    homeSearchInfo: homeSearchInfoSlice.reducer,
    homeEvents: homeEventsSlice.reducer,
    eventDetailAlbum: eventDetailAlbumSlice.reducer,
    homeCityPanelInfo: homeCityPanelSlice.reducer,
  },
});

export const homeSearchFilter = (state) => state.homeSearchInfo.filter;
export const homeEvents = (state) => state.homeEvents.events;
export const eventAlbum = (state) => state.eventDetailAlbum.photos;
export const homeCityPanelLocation = (state) => state.homeCityPanelInfo.location;

export default store;
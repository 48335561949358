import React from "react";
import { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface CommonLayoutProps {
  index: string;
  nextHref: string;
  backtHref: string;
  nextBtnText?: string;
  children: React.ReactNode;
}

const CommonLayout: FC<CommonLayoutProps> = ({
  index = "01",
  children,
  nextHref,
  nextBtnText,
  backtHref,
}) => {

  const [eventData, setEventData] = useState({
    title: '',
    description: '',
    startDateStr: '',
    endDateStr: '',
    photoURL: '',
    photoURLs: [],
    address: {
      fullAddress: '',
      city: '',
      street: '',
      state: '',
      country: 'Canada',
      postalCode: '',
      name: '',
      unit: '',
      placeId: '',
      lat: 0.0,
      lng: 0.0,
    },
    price: 0,
    tags: [],

  })

  const checkNextHref = () => {
    if (index == "01"){
      let eBasics = localStorage.getItem('create_event_basics') || '{}'
      if (eBasics == "{}"){
        nextHref = backtHref
      }
      else{
        let eventBasics = JSON.parse(eBasics) || {}
      var eventD = {
        title: eventBasics.title,
    description: eventBasics.description,
    startDateStr: eventBasics.startDateStr,
    endDateStr: eventBasics.endDateStr,
    photoURL: eventBasics.photoURL,
    photoURLs: eventBasics.photoURLs,
    address: eventBasics.address,
    price: eventBasics.price,
    tags: eventBasics.tags,
      }
      if (eventD.title == "" || eventD.description == "" || eventD.address.fullAddress == ""){
        nextHref = backtHref
      }
      }
    }
    else if (index == "02"){
      let ticketItms = localStorage.getItem('create_event_tickets') || '{}'
      if (ticketItms == "{}"){
        nextHref = "/create-event-02"
      }
    }
    else if (index == "05"){
      let orgItem = localStorage.getItem('create_event_orgs') || '{}'
      if (orgItem == "{}"){
        nextHref = backtHref
      }
    }
    return nextHref
  }

  const nextHrefOnClick = () => {
    if (index == "01"){
      let eBasics = localStorage.getItem('create_event_basics') || '{}'
      if (eBasics == "{}"){
        alert("Please fill in required fields")
        console.log("{}")
      }
      else{
        let eventBasics = JSON.parse(eBasics) || {}
      var eventD = {
        title: eventBasics.title,
    description: eventBasics.description,
    startDateStr: eventBasics.startDateStr,
    endDateStr: eventBasics.endDateStr,
    photoURL: eventBasics.photoURL,
    photoURLs: eventBasics.photoURLs,
    address: eventBasics.address,
    price: eventBasics.price,
    tags: eventBasics.tags,
      }
      if (eventD.title == "" || eventD.description == "" || eventD.address.fullAddress == ""){
        alert("Please fill in required fields")
        console.log(eventD)
      }
      }
    }
    else if (index == "02"){
      let ticketItms = localStorage.getItem('create_event_tickets') || '{}'
      if (ticketItms == "{}"){
        alert("Please fill in required fields")
      }
    }
    else if (index == "05"){
      let orgItem = localStorage.getItem('create_event_orgs') || '{}'
      if (orgItem == "{}"){
        alert("Please create valid profile")
      }
    }
  }

  return (
    <div
      className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32`}
      data-nc-id="PageAddListing1"
    >
      <div className="space-y-11">
        <div>
          <span className="text-4xl font-semibold">{index}</span>{" "}
          <span className="text-lg text-neutral-500 dark:text-neutral-400">
            / 06
          </span>
        </div>

        {/* --------------------- */}
        <div className="listingSection__wrap ">{children}</div>

        {/* --------------------- */}
        <div className="flex justify-end space-x-5">
          <ButtonSecondary href={backtHref}>Go back</ButtonSecondary>
          {index != "06" && 
          <ButtonPrimary href={checkNextHref()} onClick={()=>nextHrefOnClick()}>
            {nextBtnText || "Continue"}
          </ButtonPrimary>}
          
        </div>
      </div>
    </div>
  );
};

export default CommonLayout;

import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/eventgoBanner.png";
import GallerySlider from "components/GallerySlider/GallerySlider";
import Carousel from 'react-material-ui-carousel'
import {isMobile} from 'react-device-detect'
import HeroRealEstateSearchForm from "components/HeroSearchForm/(real-estate-search-form)/HeroRealEstateSearchForm";
import { Paper, Button } from '@mui/material'
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import { TopBannerType } from "data/types";
import { Link } from "react-router-dom";

export interface SectionHero3Props {
  className?: string;
  bannerImages?: TopBannerType[];
}


const SectionHero3: FC<SectionHero3Props> = ({ className = "", bannerImages = []}) => {
  
  return (
    <div
      className={`nc-SectionHero3 relative ${className}`}
      data-nc-id="SectionHero3"
    >
      <div style={{marginTop: 0, marginRight: 0, marginLeft: 0}}>
        <Carousel duration={3000} animation="fade" className="absolute align-items-center" IndicatorIcon={<></>}>
            {
                bannerImages.map( (item, i) => <Paper elevation={0} className="">
                  <Link to={item.link == '' ? '/' : item.link}>
                  {!isMobile && <img
                  style={{objectFit: 'cover', objectPosition: 'center', border: 'none'}}
          //style={{objectFit: 'cover', objectPosition: 'center', height: '50vh', width: '100%'}}
          src={item.image}
          alt="eventgo image"
        />}
        {isMobile && <img
                  style={{objectFit: 'cover', objectPosition: 'center', border: 'none'}}
          //style={{objectFit: 'fill', objectPosition: 'center', height: '40vh', width: '100%'}}
          src={item.image}
          alt="eventgo image"
        />}
                  
                  </Link>
                </Paper> )
            }
        </Carousel>
        <div hidden={isMobile} className={"hidden lg:block lg:mt-8 w-full"}>
          <HeroSearchForm/>
        </div>
      </div>
    </div>
  );
};

export default SectionHero3;

import React, { ReactNode } from "react";
import { imageGallery as listingStayImageGallery } from "./listing-stay-detail/constant";
import { imageGallery as listingCarImageGallery } from "./listing-car-detail/constant";
import { imageGallery as listingExperienceImageGallery } from "./listing-experiences-detail/constant";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ListingImageGallery from "components/ListingImageGallery/ListingImageGallery";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { useSelector } from "react-redux";
import { eventAlbum } from "store";
import { ListingGalleryImage } from "components/ListingImageGallery/utils/types";

const EventDetailPagetLayout = ({ children}: { children: ReactNode}) => {
  const navigate = useNavigate();
  const thisPathname = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const modal = searchParams?.get("modal");
  const photos:any = useSelector(eventAlbum)

  const handleCloseModalImageGallery = () => {
    let params = new URLSearchParams(document.location.search);
    params.delete("modal");
    navigate(`${thisPathname}/?${params.toString()}`);
  };

  const getImageGalleryListing = () => {
    if(photos != null && photos.length > 0){
      return [...photos].map(
        (item, index): ListingGalleryImage => {
          return {
            id: index,
            url: item,
          };
        })
    }else{
      return []
    }
  }

  return (
    <div className="ListingDetailPage">
      <ListingImageGallery
        isShowModal={modal === "event-collections"}
        onClose={handleCloseModalImageGallery}
        images={getImageGalleryListing()}
      />

      <div className="container ListingDetailPage__content">{children}</div>

      {/* OTHER SECTION */}
      {/* <div className="container py-24 lg:py-32">
        <SectionSubscribe2 className="pt-24 lg:pt-32" />
      </div> */}

    </div>
  );
};

export default EventDetailPagetLayout;

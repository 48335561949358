import { PostDataType } from "data/types";
import React, { FC, useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet-async";
import { DEMO_CATEGORIES } from "data/taxonomies";

const BlogSingle = () => {
  
  const [searchParams] = useSearchParams();
  const newsId = searchParams.get('newsId');

  const  [post, setPost] = useState<PostDataType>();
  
  function convertTime(timeStr:string){
    var ts = Date.parse(timeStr);
    const formatter = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' , hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
    const formattedDate = formatter.format(ts);
    return formattedDate;
}

  useEffect(()=>{
    
    fetch("https://us-central1-blink-574af.cloudfunctions.net/v1NewsDetail?newsId="+newsId,{
      headers: {
        "Content-Type": "application/json",
      },

    }).then((response) => {
        if(response.status === 200){
          const responseTask = response.json();
          responseTask.then((resp) => {
              if(resp.success === true){
                setPost({
                  ...resp.data,
                  published:convertTime(resp.data.published),
                  categories:[DEMO_CATEGORIES[resp.data.categories[0]-1]]
                });
              }
          })
        }
      })
  },[]);

  const evtgoUrl = 'https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/eventgo.JPG';

  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <Badge hide={post==null} href={post!=null?post.categories[0].href:'/'} color="purple" name={post!=null?post.categories[0].name:''} />
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            {post!=null ? post.title:''}
          </h1>
          {/* <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
            {post!=null ? (post.shortcut!.length < 20?post.shortcut:post.shortcut?.slice(0, 18)+'...'):''}
          </span> */}

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col items-baseline sm:flex-row sm:justify-between">
            <div className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
              <Avatar
                containerClassName="flex-shrink-0"
                sizeClass="w-8 h-8 sm:h-11 sm:w-11 "
                imgUrl={evtgoUrl}    
                display={post==null?false:true}            
              />
              <div className="ml-3">
                <div className="flex items-center">
                  <a className="block font-semibold" href="/">
                    {'Event Go'}
                  </a>
                </div>
                <div className="text-xs mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    {post!=null ? post.published:''}
                  </span>
                  <span className="text-neutral-700 dark:text-neutral-300">
                    {post!=null ? post.commentCount+' ':''}
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="mt-3 sm:mt-0 sm:ml-3">
              <SocialsList />
            </div> */}
          </div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <div style={{ minHeight: "800px" }}
          dangerouslySetInnerHTML={{
            __html: post != null?post?.content!:''
          }}></div>
      </div>
    );
  };

  const renderTags = () => {
    return (
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Garden
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Jewelry
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Tools
        </a>
      </div>
    );
  };

  const renderAuthor = () => {
    return (
      <div className="max-w-screen-md mx-auto ">
        <div className="nc-SingleAuthor flex">
          <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
          <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
            <span className="text-xs text-neutral-400 uppercase tracking-wider">
              WRITEN BY
            </span>
            <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
              <a href="/">Fones Mimi</a>
            </h2>
            <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
              There’s no stopping the tech giant. Apple now opens its 100th
              store in China.There’s no stopping the tech giant.
              <a className="text-primary-6000 font-medium ml-1" href="/">
                Readmore
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderCommentForm = () => {
    return (
      <div className="max-w-screen-md mx-auto pt-5">
        <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
          Responses (14)
        </h3>
        <form className="nc-SingleCommentForm mt-5">
          <Textarea />
          <div className="mt-2 space-x-3">
            <ButtonPrimary>Submit</ButtonPrimary>
            <ButtonSecondary>Cancel</ButtonSecondary>
          </div>
        </form>
      </div>
    );
  };

  const renderCommentLists = () => {
    return (
      <div className="max-w-screen-md mx-auto">
        <ul className="nc-SingleCommentLists space-y-5">
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  };

  const renderPostRelated = (post: PostDataType) => {
    return (
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
      >
        <Link to={post.selfLink} />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name="Categories" />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>

          <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {post.author.displayName}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">{post.published}</span>
          </div>
        </div>
        <Link to={post.selfLink} />
      </div>
    );
  };

  console.log("newsId=====>"+newsId);
  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>{post?.title || 'News'} || Event Go</title>
        <link rel="canonical" href={`https://evtgo.com/blog-single?newsId=${newsId || ""}`} />
        <meta property="og:title" content={`${post?.title || ''} | Event Go`} />
        <meta property="og:description" content={`${post?.shortcut || ''} | Event Go`} />
        <meta property="og:url" content={`https://evtgo.com/blog-single?newsId=${newsId || ""}`}/>
        <meta property="og:site_name" content={`${post?.title || ''} | Event Go`} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={post?.featuredImage || ''} />
      </Helmet>
      {renderHeader()}
      <div className="nc-SingleContent container space-y-10" style={{marginTop:20}}>
        {renderContent()}
        {/* {renderTags()} */}
        {/* <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div> */}
        {/* {renderAuthor()} */}
      </div>
    </div>
  );
};

export default BlogSingle;

"use client";

import { ClockIcon, DocumentIcon, MapPinIcon } from "@heroicons/react/24/outline";
import React, { useState, useRef, useEffect, FC } from "react";
import ButtonSubmit from "./ButtonSubmit";

export interface KeywordsInputProps {
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  hasButtonSubmit?: boolean;
  buttonSubmitHref?:string;
  handleSearchInfo?:any;
  handleDoSearchEvent?:any;
}

const KeywordsInput: FC<KeywordsInputProps> = ({
  autoFocus = false,
  placeHolder = "Type event name or description",
  desc = "searching for event?",
  className = "nc-flex-1.5",
  hasButtonSubmit= true,
  buttonSubmitHref='',
  handleSearchInfo=null,
  handleDoSearchEvent = null,
  divHideVerticalLineClass = "left-10 -right-0.5",
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState("");


  useEffect(() => {
    if(inputRef != null && inputRef.current != null){
      inputRef.current.focus()
    }
  }, [autoFocus]);

  const searchEvent = ()=>{
    if(handleDoSearchEvent){
      handleDoSearchEvent()
    }
  }

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <DocumentIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow">
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder={placeHolder}
            value={value}
            onChange={(e) => {
              setValue(e.currentTarget.value);
              if(handleSearchInfo){
                handleSearchInfo(e.currentTarget.value)
              }
            }}
            ref={inputRef}
          />
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="line-clamp-1">{!!value ? placeHolder : desc}</span>
          </span>
        </div>
      </div>
      {hasButtonSubmit && (
              <div className="pr-2 xl:pr-4 self-center">
                <ButtonSubmit href={buttonSubmitHref}/>
              </div>
            )}      
    </div>
  );
};

export default KeywordsInput;
